export default {
    behindBG: -1,
    BG: 0,
    level1: 10,
    level2: 20,
    level3: 30,
    checkbox: 50,
    dropdown: 100,
    sectionBG: 1000,
    top: 1500,
    overtop: 1750,
    navigation: 2000,
    modal: 3000,
}